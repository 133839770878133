import React from "react";
import '../style/skills.css'

const Skills = () =>{

    return (
        <div className="skills-bar">
            <div className="skill1"></div>
            <div className="skill2"></div>
            <div className="skill3"></div>
            <div className="skill4"></div>
            <div className="skill5"></div>
            <div className="skill6"></div>
            <div className="skill7"></div>
            <div className="skill8"></div>
            <div className="skill9"></div>
            <div className="skill10"></div>
            <div className="skill11"></div>
            <div className="skill12"></div>
            <div className="skill13"></div>
            <div className="skill14"></div>
        </div>
    )

}
export default Skills